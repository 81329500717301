<pp-card>
  <pp-card-number>1</pp-card-number>
  <pp-card-title>{{ 'PP_HOW_TO_PAY_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_HOW_TO_PAY' | translate"></p>
    <!--<a routerLink="/account" class="btn btn-success">VIEW & PAY ticket</a>-->
  </pp-card-description>
</pp-card>

<pp-card>
  <pp-card-number>2</pp-card-number>
  <pp-card-title>{{ 'PP_HOW_TO_APPEAL_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_HOW_TO_APPEAL' | translate"></p>
    <!--<a routerLink="/account/appeal" class="btn btn-success">make an appeal</a>-->
  </pp-card-description>
</pp-card>

<pp-card>
  <pp-card-number>3</pp-card-number>
  <pp-card-title>{{ 'PP_TYPES_OF_VIOLATIONS_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <p [innerHTML]="'PP_TYPES_OF_VIOLATIONS' | translate"></p>
  </pp-card-description>
</pp-card>

<pp-card>
  <pp-card-number>4</pp-card-number>
  <pp-card-title>{{ 'PP_FURTHER_QUESTIONS_TITLE' | translate }}</pp-card-title>
  <pp-card-description>
    <ngb-accordion #acc="ngbAccordion" class="accordion">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <h6>{{ 'PP_FAQ1' | translate }}</h6>
        </ng-template>
        <ng-template ngbPanelContent>
          {{ 'PP_FAQ1Answer' | translate }}
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <h6>{{ 'PP_FAQ2' | translate }}</h6>
        </ng-template>
        <ng-template ngbPanelContent>
          {{ 'PP_FAQ2Answer' | translate }}
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <h6>{{ 'PP_FAQ3' | translate }}</h6>
        </ng-template>
        <ng-template ngbPanelContent>
          {{ 'PP_FAQ3Answer' | translate }}
        </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <h6>{{ 'PP_FAQ4' | translate }}</h6>
        </ng-template>
        <ng-template ngbPanelContent>
          {{ 'PP_FAQ4Answer' | translate }}
        </ng-template>
      </ngb-panel>

    </ngb-accordion>

    <h6 class="mt-4">{{ 'PP_FAQtext' | translate }}</h6>

  </pp-card-description>
</pp-card>


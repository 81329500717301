import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PaymentCoreService {
  private paymentData: BehaviorSubject<any> = new BehaviorSubject(null);
  private invoicesArray: BehaviorSubject<any> = new BehaviorSubject(null);

  public paymentData$: Observable<any>;
  public invoicesArray$: Observable<any>;

  constructor() {
    this.paymentData$ = this.paymentData.asObservable();
    this.invoicesArray$ = this.invoicesArray.asObservable();
  }

  public updatePaymentData(data) {
    this.paymentData.next(data);
  }

  // public updateStatus(arr) {
  //   const curVal = this.invoicesArray.value.map(item => {
  //     const itemIndex = arr.indexOf(item.referenceNumber);
  //     return itemIndex !== -1 ? {...item, status: StatusInvoice.PAID} : item;
  //   });
  //   this.invoicesArray.next(curVal);
  // }

  public updateInvoicesArray(arr) {
    this.invoicesArray.next(arr);
  }

  public resetData() {
    this.paymentData.next(null);
    this.invoicesArray.next(null);
  }
}

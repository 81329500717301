import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule, Routes } from '@angular/router';
import { SharedComponentsModule } from '@frontend/shared/components';
import { HttpTranslateFactory } from '@frontend/shared/helpers';
import { EnvironmentService } from '@frontend/shared/services';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RepEffects } from './+state/rep.effects';
import * as fromRep from './+state/rep.reducer';
import { RepLayoutComponent } from './rep-layout/rep-layout.component';
import { Step1Container } from './step1/step1.container';
import { Step2Container } from './step2/step2.container';
import { Step3Container } from './step3/step3.container';
import { Step4Container } from './step4/step4.container';
import { repConfiguration } from './configuration/rep.configuration';

const routes: Routes = [
  {
    path: '',
    component: RepLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'step1',
        pathMatch: 'full',
      },
      {
        path: 'step1',
        component: Step1Container,
      },
      {
        path: 'step2',
        component: Step2Container,
      },
      {
        path: 'step3',
        component: Step3Container,
      },
      {
        path: 'step4',
        component: Step4Container,
      },
    ],
  },

  {
    path: '**',
    redirectTo: 'step1',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedComponentsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FlexLayoutModule,
    StoreModule.forFeature(fromRep.REP_FEATURE_KEY, fromRep.reducer),
    EffectsModule.forFeature([RepEffects]),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    TranslateModule.forChild({
      loader: {
        deps: [HttpClient, EnvironmentService],
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
      },
    }),
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatCheckboxModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
  ],

  declarations: [
    repConfiguration.step1,
    Step1Container,
    RepLayoutComponent,
    repConfiguration.step2,
    Step2Container,
    repConfiguration.step3,
    Step3Container,
    repConfiguration.step4,
    Step4Container,
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class SharedRepModule {}

import { RepConfigurationModel } from './rep-configuration.model';
import { Step1Component } from '../step1/step1.component';
import { Step2Component } from '../step2/step2.component';
import { Step3Component } from '../step3/step3.component';
import { Step4Component } from '../step4/step4.component';

export const repConfiguration: RepConfigurationModel = {
  step1: Step1Component,
  step2: Step2Component,
  step3: Step3Component,
  step4: Step4Component,
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';

import { InvoiceModel } from '../models';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class InvoiceService {
  constructor(private http: HttpClient) {}

  private get url(): string {
    return `${environment.apiUrl}/${environment.version}/claims`;
  }

  public getAdyenSession(body: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/adyen/v1/session/`, body);
  }

  public getInvoice(referenceNumber: number): Observable<InvoiceModel> {
    return this.getClaims() // todo: check it
      .pipe(
        map((data) => {
          return data.claims.find(
            (item) => item.referenceNumber === referenceNumber
          );
        })
      );
  }

  public getClaims(currency?: string): Observable<any> {
    const url = currency ? `${this.url}/?currency=${currency}` : `${this.url}`;
    return this.http.get(url).pipe(
      catchError((error) => {
        return error;
      })
    );
  }

  public getImage(referenceNumber: number, imageName: string): Observable<any> {
    if (imageName.includes('http')) {
      return of(imageName);
    }
    return this.http.get(`${this.url}/${referenceNumber}/images/${imageName}`);
  }

  public setPayment(body: object): Observable<any> {
    return this.http.post(`${this.url}/payments`, body);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  appAuthCleanUpItems,
  CoreStorageEnum,
  RequestHeaders,
  ServerTypes,
} from '@frontend/shared/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { EnvironmentService } from './environment.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private $isLogged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.isAuthenticated()
  );
  public isLogged: Observable<boolean>;
  public tempLogged = false;

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
    private storage: StorageService,
    private environmentService: EnvironmentService
  ) {
    this.isLogged = this.$isLogged.asObservable();
  }

  public get url(): string {
    return this.apiService.getCurentServer(ServerTypes.auth);
  }

  public isAuthenticated(): boolean {
    return !!this.getAuthToken();
  }

  public getAuthToken(): string {
    return this.storage.getLocalStorageItem(CoreStorageEnum.tokenName);
  }

  public setAuthToken(token: string, type: string, clientId: string) {
    this.storage.setLocalStorageItem(CoreStorageEnum.tokenName, token);
    this.storage.setLocalStorageItem(CoreStorageEnum.tokenType, type);
    this.storage.setLocalStorageItem(CoreStorageEnum.clientId, clientId);
  }

  public getClientId(): string {
    return this.storage.getLocalStorageItem(CoreStorageEnum.clientId);
  }

  public getLastCheck(): Date {
    return new Date(
      this.storage.getLocalStorageItem(CoreStorageEnum.tokenLastCheck)
    );
  }
  public setLastCheck() {
    this.storage.setLocalStorageItem(
      CoreStorageEnum.tokenLastCheck,
      new Date().toString()
    );
  }

  public getTokenType(): string {
    return this.storage.getLocalStorageItem(CoreStorageEnum.tokenType);
  }

  public login(
    username: string,
    password: string,
    clientId: string
  ): Observable<any> {
    const body = {
      version: this.environmentService.appVersion,
      clientId,
      username,
      password,
    };

    return this.httpClient
      .post(`${this.url}/v1/auth/token`, body, {
        headers: this.apiService.getHeaders(ServerTypes.auth, 0),
      })
      .pipe(
        tap((res) => {
          this.storage.setSessionStorageItem(
            CoreStorageEnum.username,
            username
          );
          this.setAuthToken(
            res[CoreStorageEnum.tokenName],
            res[CoreStorageEnum.tokenType],
            res[CoreStorageEnum.clientId]
          );
          this.setLastCheck();
          this.$isLogged.next(true);
        })
      );
  }

  public logout(): Observable<any> {
    const headers = this.apiService
      .getHeaders(ServerTypes.auth, 0)
      .set(
        RequestHeaders.auth,
        this.getTokenType() + ` ${this.getAuthToken()}`
      );

    if (this.isAuthenticated()) {
      return this.httpClient
        .delete(`${this.url}/v1/auth/token`, { headers })
        .pipe(
          finalize(() => {
            this.clearUserData();
          })
        );
    } else {
      this.$isLogged.next(false);
      return of(false);
    }
  }

  public async isAuthenticatedAsync() {
    if (!this.isAuthenticated()) {
      return false;
    }

    const lastCheck = this.getLastCheck();
    console.log(this.getDateDiff(lastCheck) + 'ms since last check.');
    if (this.getDateDiff(lastCheck) < 60000) {
      return true;
    } // check token once per minute

    const headers = this.apiService
      .getHeaders(ServerTypes.auth, 0)
      .set(
        RequestHeaders.auth,
        this.getTokenType() + ` ${this.getAuthToken()}`
      );
    await this.httpClient
      .get(`${this.url}/v1/auth/token`, { headers })
      .toPromise()
      .then(
        (res) => {
          console.log('token valid');
          this.tempLogged = true;
        },
        (err) => {
          this.tempLogged = false;
          this.clearUserData();
        }
      );
    return this.tempLogged;
  }
  public getDateDiff(startDate) {
    if (startDate == null) {
      return 99999999;
    }
    return new Date().getTime() - startDate.getTime();
  }

  public hasValidAuthToken(): Observable<any> {
    if (this.isAuthenticated()) {
      const headers = this.apiService
        .getHeaders(ServerTypes.auth, 0)
        .set(
          RequestHeaders.auth,
          this.getTokenType() + ` ${this.getAuthToken()}`
        );
      return this.httpClient.get(`${this.url}/v1/auth/token`, { headers }).pipe(
        tap(() => {
          this.$isLogged.next(true);
        }),
        catchError((err) => {
          this.clearUserData();
          return of(err);
        })
      );
    }
    return of(true);
  }

  public clearUserData() {
    this.storage.removeLocalStorageItem(CoreStorageEnum.tokenName);
    this.storage.removeLocalStorageItem(CoreStorageEnum.tokenLastCheck);
    this.storage.removeLocalStorageItem(CoreStorageEnum.tokenType);
    this.storage.removeLocalStorageItem(CoreStorageEnum.clientId);

    this.storage.removeSessionStorageItem(CoreStorageEnum.username);

    if (appAuthCleanUpItems.length) {
      appAuthCleanUpItems.forEach((item) => {
        this.storage.removeSessionStorageItem(String(item));
      });
    }
    this.$isLogged.next(false);
  }
}

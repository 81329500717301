import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/auth';
import { LogoutComponent } from './pages/auth';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountModule),
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'login', component: LoginComponent },
  {
    path: ':lang/pay',
    loadChildren: () =>
      import('@frontend/shared/payment').then((m) => m.SharedPaymentModule),
  },
  {
    path: ':lang/rep',
    loadChildren: () =>
      import('@frontend/shared/rep').then((m) => m.SharedRepModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

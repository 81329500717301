import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComplaintData } from '../+state/rep.reducer';

@Component({
  selector: 'rep-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Step2Component implements OnInit {
  _formData: ComplaintData;
  get formData(): ComplaintData {
    return this._formData;
  }
  @Input() set formData(newFormData: ComplaintData) {
    this._formData = newFormData;
    this.form.patchValue(newFormData, { emitEvent: false });
  }

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    complaint: new FormControl('', [Validators.required]),
  });

  @Output() formChanged = new EventEmitter<{
    email: string;
    complaint: string;
  }>();

  @Output() proceed = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.formChanged.emit(this.form.getRawValue());
    });
  }

  submitForm() {
    if (this.form.valid) this.proceed.emit();
  }
}

import {Injectable} from '@angular/core';

@Injectable()
export class PaymentService {
  private paymentData = null;
  private invoicesArray = null;

  public get data() {
    return this.paymentData;
  }

  public set data(paymentData) {
    this.paymentData = paymentData;
  }

  public set invoices(invoices) {
    this.invoicesArray = invoices;
  }

  public get invoices() {
    return this.invoicesArray;
  }

  public resetData() {
    this.paymentData = null;
    this.invoicesArray = null;
  }

}

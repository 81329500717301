import { Component, Input } from '@angular/core';
import { BaseThemedComponent } from '../base-themed.component';
import { ThemeProvider } from '@frontend/shared/services';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'frontend-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent extends BaseThemedComponent {
  @Input() formGroup: FormGroup;

  constructor(themeProvider: ThemeProvider) {
    super(themeProvider);
  }
}

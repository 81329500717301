<mat-card
  gdArea
  gdRows="auto 1fr auto"
  gdRows.lt-md="auto auto 1fr auto"
  gdColumns.gt-sm="1fr 3fr 1fr"
  gdColumns.lt-md="1fr"
  [gdColumns.gt-sm]="contentLayout === 'stretch' ? '1fr' : '1fr 3fr 1fr'"
>
  <div gdRow="1" gdColumn="1" gdColumn.lt-md="1">
    <ng-content select="[topLeft]"></ng-content>
  </div>

  <div
    gdRow="1"
    gdRow.lt-md="2"
    gdColumn.lt-md="1"
    [gdColumn]="contentLayout === 'stretch' ? '1' : '2'"
    *ngIf="title && title.length > 0"
    class="frontend-card-titles'"
  >
    <h1>{{ title }}</h1>
    <h2>{{ subtitle }}</h2>
  </div>

  <div
    gdRow="2"
    gdRow.lt-md="3"
    [gdColumn]="contentLayout === 'stretch' ? '1' : '2'"
    gdColumn.lt-md="1"
  >
    <ng-content select="[content]"></ng-content>
  </div>

  <div
    gdRow="3"
    gdRow.lt-md="4"
    [gdColumn]="contentLayout === 'stretch' ? '1' : '2'"
    gdColumn.lt-md="1"
  >
    <ng-content select="[footer]"></ng-content>
  </div>
</mat-card>

import { UnsubscriberComponent } from '../../unsubscriber.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ElementRef, ViewChild, Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageEnum, CoreStorageEnum } from '@frontend/shared/models';
import { EnvironmentService, StorageService } from '@frontend/shared/services';
import { LoadService } from '@frontend/shared/services';
import { AuthService } from '@frontend/shared/services';
import { notOnlySpacesValidator } from '../../validators/not-only-spaces-validator';

declare var adyen: {
  encrypt: {
    createEncryption(key: string, options: any): void;
  },
}

declare var ThreedDS2Utils: {
  config: {
    getChallengeWindowSize(sizeStr: string): any;
    validateChallengeWindowSize(sizeStr: string): any;
  },
  base64Url: {
    decode(str: string): string;
    encode(str: string): string;
  },
  createIframe(container: any, name: any, width: any, height: any, callback?): any;
  createForm(name: any, action: any, target: any, inputName: any, inputValue: any): any;
}


@Directive()
export abstract class CommonCardClass extends UnsubscriberComponent {
  public cardForm: FormGroup;
  public message: { text?: string; isSuccess?: boolean };
  public error: string = null;
  public isFixNumberSpace = false;
  public cseInstance: any;
  public isFormSubmitted = false;


  public successUrl: string;
  public successWithOutstandingUrl: string;
  public errorUrl: string;
  @ViewChild('currency', { read: ElementRef, static: true })
  public currency: ElementRef;
  @ViewChild('expDate', { read: ElementRef, static: true })
  public expDate: ElementRef;
  @ViewChild('cardNumber', { read: ElementRef, static: true })
  public cardNumber: ElementRef;
  @ViewChild('cvc', { read: ElementRef, static: true }) public cvc: ElementRef;
  @ViewChild('form3d', { read: ElementRef, static: true })
  public form3d: ElementRef;
  @ViewChild('pa', { read: ElementRef, static: true }) public pa: ElementRef;
  @ViewChild('md', { read: ElementRef, static: true }) public md: ElementRef;
  @ViewChild('termUrl', { read: ElementRef, static: true })
  public termUrl: ElementRef;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public loadService: LoadService,
    public authService: AuthService,
   // protected appInsights: ApplicationInsightsService,
    protected storage: StorageService,
    protected environmentService: EnvironmentService
  ) {
    super();
  }

  public initForm(): void {
    this.error = null;
    const v = window.location.search.match(new RegExp('(?:[?&]error=)([^&]+)'));
    this.error = v ? v[1] : null;

    this.cardForm = this.fb.group({
      currency: [],
      creditCardNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('([0-9]{4}\\s+){3}[0-9]{4}\\s*'),
          Validators.minLength(10),
        ],
      ],
      cvc: ['', [Validators.required, Validators.pattern('[0-9]{3}')]],
      name: [
        '',
        [
          Validators.required,
          Validators.pattern('\\D+'),
          Validators.minLength(3),
          notOnlySpacesValidator(),
        ],
      ],
      expdate: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          // expDateValidator(this.dateLength),
        ],
      ],
    });
    this.subscriptions.add(
      this.cardForm
        .get('expdate')
        .valueChanges.subscribe((val) => this.addSlashExpDate())
    );
  }

  @HostListener('window:message', ['$event'])
  onMessage(message) {
    console.log("message received: ", message);
    
    if(!!message.data && message.data.sender === 'adyen_3DS') {
      
      if(message.data.status === 'success') {
        console.log("success!");
        // Navigate to success url
        this.loadService.hide();
        this.router.navigate([message.data.value]);
      }
      else if(message.data.status === 'error') {  
        document.getElementById('threedsContainer').innerHTML = '';
        this.handleError({error: message.data.value});                       
      } 
      else if(message.data.status === 'challange') {
        document.getElementById('threedsContainer').innerHTML = '';
        this.handleChallengeShopper({additionalData: JSON.parse(ThreedDS2Utils.base64Url.decode(message.data.value))});                   
      } 
      else {
        console.warn("CommonCard - Unreckognized sender:", message);
      }
    }
  }

  public createCSEinstance(): void {
    var clientid = localStorage.getItem('clientId');
    var key = clientid == '46028' ? this.environmentService.environment.adyenTestKey : this.environmentService.environment.adyenKey ;
    console.log(clientid);
    const options = { enableValidations: false };
    this.cseInstance = adyen.encrypt.createEncryption(
      key,
      options
    );

    // Enable behavior tracking on number and CVC fields
    // Initiate before user interacts with the refeenced fields.
    this.cseInstance.monitor('number', this.cardNumber.nativeElement);
    this.cseInstance.monitor('cvc', this.cvc.nativeElement);
  }

  public addSpacesToExpDate(event) {
    const target = this.expDate.nativeElement;
    let position = target.selectionEnd;
    const length = target.value.length;
    if (event) {
      target.value = event
        .replace('/', ' ')
        .replace(/\D/g, '')
        .replace(/\s/g, '')
        .replace(/(\d{2})/g, '$1 ')
        .trim();
      position +=
        target.value.charAt(position - 1) === ' ' &&
        target.value.charAt(length - 1) === ' ' &&
        length !== target.value.length
          ? 1
          : 0;
      target.value = target.value.replace(' ', '/');
      target.selectionEnd = position;
    }
  }

  public addSpacesToCardNumber(event) {
    this.isFixNumberSpace = !this.isFixNumberSpace;
    if (this.isFixNumberSpace) {
      const target = this.cardNumber.nativeElement;
      let position = target.selectionEnd;
      const length = target.value.length;

      if (event) {
        target.value = event
          .replace(/\D/g, '')
          .replace(/\s/g, '')
          .replace(/(\d{4})/g, '$1 ')
          .trim();
      }
      position +=
        target.value.charAt(position - 1) === ' ' &&
          target.value.charAt(length - 1) === ' ' &&
          length !== target.value.length
          ? 1
          : 0;
      target.selectionEnd = position;
      this.cardForm.get('creditCardNumber').setValue(target.value);
    }
  }

  public initRequest(): any {
    this.cardForm.updateValueAndValidity();
    if (this.cardForm.invalid) {
      return null;
    }
    const form: FormGroup = this.cardForm;
    const returnUrl = `${document.location.protocol}//${document.location.hostname}${document.location.port !== "80" ?  ":" + document.location.port : ""}/`;

    const obj = {
      currency: form.get('currency').value,
      encryptedData: this.encryptData(form, this.cseInstance),
      accountNumber: this.getMaskedCardNumber(),
      accountName: this.getMaskedName(),
      expDate: this.formatExpDate(form.get('expdate').value),
      testId: this.environmentService.environment.testId,
      timestamp: Date.now(),
      successUrl: this.successUrl,
      errorUrl: this.errorUrl,
      root: returnUrl
    };
    this.storage.setSessionStorageItem('accountNumber', obj.accountNumber);
    this.storage.setSessionStorageItem('expdate', obj.expDate);
    this.isFormSubmitted = true;

    this.error = null;
    this.loadService.show();
    return obj;
  }
  public handleResponse(res: any) {
    this.storage.setSessionStorageItem(AppStorageEnum.successUrl, this.successUrl);
    this.storage.setSessionStorageItem(AppStorageEnum.reference, res['reference']);
    this.storage.setSessionStorageItem(AppStorageEnum.puid, res['puid']);
    this.storage.setSessionStorageItem(AppStorageEnum.paymentTime, new Date().toISOString());
    console.log(this.successUrl)

    if (res[CoreStorageEnum.tokenName]) {
      this.authService.setAuthToken(res[CoreStorageEnum.tokenName], 'Bearer', this.environmentService.environment.accountClientId);
    }

    if (res.redirect) {
      if (res.resultCode === 'ChallengeShopper') { this.handleChallengeShopper(res); return; }
      if (res.resultCode === 'IdentifyShopper') { this.handleIdentifyShopper(res); return; }
      this.handleRedirect(res);
      return;
    }

    if (res.outstandingClaims && this.successWithOutstandingUrl) {
      this.router.navigate([this.successWithOutstandingUrl]);
      return;
    }

    this.loadService.hide();
    this.router.navigate([this.successUrl]);
  }

  public handleRedirect(data) {
    this.pa.nativeElement.value = data.paRequest;
    this.md.nativeElement.value = data.md;
    this.form3d.nativeElement.action = data.redirectUrl;
    this.termUrl.nativeElement.value = data.notificationUrl;
    this.form3d.nativeElement.submit();
  }

  public handleIdentifyShopper(responseData) {
      const notificationUrl = responseData.notificationUrl; 
      const serverTransactionID = responseData.additionalData['threeds2.threeDSServerTransID'];
      const threeDSMethodURL = responseData.additionalData['threeds2.threeDSMethodURL'];
      const threedsContainer = document.getElementById('threedsContainer');
      const dataObj = { threeDSServerTransID : serverTransactionID, threeDSMethodNotificationURL : notificationUrl };
      const stringifiedDataObject = JSON.stringify(dataObj);

      // Encode data
      const base64URLencodedData = ThreedDS2Utils.base64Url.encode(stringifiedDataObject);
      const IFRAME_NAME = 'threeDSMethodIframe';
      console.log("create iframe");

      console.log(threeDSMethodURL);

      const iframe = ThreedDS2Utils.createIframe(threedsContainer, IFRAME_NAME, '0', '0');
      // Create a form that will use the iframe to POST data to the threeDSMethodURL
      const form =  ThreedDS2Utils.createForm('threedsMethodForm', threeDSMethodURL, IFRAME_NAME, 'threeDSMethodData', base64URLencodedData);
      threedsContainer.appendChild(form);
 //     setTimeout( function () { threedsContainer.removeChild( form ); }, 1000 );
      form.submit();
      console.log("submit form");
  }

  public handleChallengeShopper(data) {
    const challengeWindowSize = ThreedDS2Utils.config.validateChallengeWindowSize('05');// Corresponds to a 600px x 400px iframe size
    const acsURL = data.additionalData['threeds2.threeDS2ResponseData.acsURL'];
    const cReqData = {
        threeDSServerTransID : data.additionalData['threeds2.threeDS2ResponseData.threeDSServerTransID'],
        acsTransID : data.additionalData['threeds2.threeDS2ResponseData.acsTransID'],
        messageVersion : data.additionalData['threeds2.threeDS2ResponseData.messageVersion'],
        messageType : 'CReq',
        challengeWindowSize
    };

    const stringifiedDataObject = JSON.stringify(cReqData);
    const base64URLencodedData = ThreedDS2Utils.base64Url.encode(stringifiedDataObject);
    const IFRAME_NAME = 'threeDSChallengeIframe';
    const threedsContainer = document.getElementById('threedsContainer');
    const iframeSizesArr = ThreedDS2Utils.config.getChallengeWindowSize(challengeWindowSize);
    const iframe = ThreedDS2Utils.createIframe(threedsContainer, IFRAME_NAME, iframeSizesArr[0], iframeSizesArr[1], null);
    const form = ThreedDS2Utils.createForm('cReqForm', acsURL, IFRAME_NAME, 'creq', base64URLencodedData);

    threedsContainer.appendChild(form);
    setTimeout(q => this.loadService.hide(), 1000 );
    
    setTimeout( function () { threedsContainer.removeChild( form ); }, 3000 );

    form.submit();
  }

  public handleError(err) {
    this.error = err.error;
    this.loadService.hide();

    const prefix = 'eWallet.CoreExceptions.';
    switch (err.error) {
      case prefix + 'InvalidCVNException': {
        this.cardForm.get('cvc').setErrors({ invalid: true });
        break;
      }
      case prefix + 'RestrictedCardException':
      case prefix + 'BlockedCardException':
      case prefix + 'InvalidCardNumberException': {
        this.cardForm.get('creditCardNumber').setErrors({ invalid: true });
        break;
      }
      case prefix + 'CardExpiredException': {
        this.cardForm.get('expdate').setErrors({ invalid: true });
        break;
      }
    }
  }

  public encryptData(form: FormGroup, cseInstance: any): string {
    const expDatePartials = this.formatExpDate(form.get('expdate').value).split('/');
    expDatePartials.map((part) => part.trim);
    let expiryYear;

    if (expDatePartials[1]) {
      expiryYear = expDatePartials[1].trim().length === 2 ? `20${expDatePartials[1].trim()}` : expDatePartials[1].trim();
    }

    const cardData = {
      number: form.get('creditCardNumber').value.replace(/\D/g, ''),
      cvc: form.get('cvc').value,
      holderName: form.get('name').value.toUpperCase(),
      expiryMonth: expDatePartials[0].trim(),
      expiryYear,
      generationtime: new Date(),
    };

    return cseInstance.encrypt(cardData);
  }

  public getMaskedCardNumber(): string {
    const cardNumber = this.cardForm
      .get('creditCardNumber')
      .value.replace(/\D/g, '');
    const masked = cardNumber.replace(cardNumber.substr(4, 8), '********');
    return masked;
  }

  public getMaskedName() {
    const name: string = this.cardForm.get('name').value;
    return name.replace(name.substr(4), name.substr(4).replace(/\S/g, '*'));
  }

  public addSlashExpDate() {
    const target = this.expDate.nativeElement;
    const length = target.value.length;
    target.value = this.formatExpDate(target.value);
    target.selectionEnd +=
      target.value.charAt(target.selectionEnd - 1) === ' ' &&
        target.value.charAt(length - 1) === ' ' &&
        length !== target.value.length
        ? 1
        : 0;
  }

  public formatExpDate(expDate: string): string {
    expDate = expDate
      .replace('.', ' ')
      .replace('-', ' ')
      .replace('/', ' ')
      .replace(/\D/g, '')
      .replace(/\s/g, '')
      .replace(/(\d{2})/g, '$1 ')
      .trim()
      .replace(' ', '/');

    if (expDate.length === 5) {
      const expDatePartials = expDate.split('/');
      if (
        expDatePartials[1] &&
        parseInt(expDatePartials[0], 10) >= 19 &&
        parseInt(expDatePartials[1], 10) <= 12
      ) {
        return expDatePartials[1] + '/' + expDatePartials[0];
      }
    }
    return expDate;
  }
}

<div class="wrapper" fxFlex fxLayout="column">
  <div
    fxFlex="{{ '0 0' + ' ' + headerHeight }}"
    class="header"
    ngClass.lt-md="header wide"
    gdArea
    gdColumns.lt-md="1fr"
    [gdColumns.gt-sm]="contentGridColumns"
    gdRows="minmax(0, 1fr)"
  >
    <div gdColumn.lt-md="1" gdColumn.gt-sm="2">
      <ng-content select="frontend-header"></ng-content>
    </div>
  </div>
  <main
    gdArea
    gdColumns.lt-md="1fr"
    [gdColumns.gt-sm]="contentGridColumns"
    gdRows.lt-md="1fr"
    gdRows.gt-sm="5fr 3fr"
  >
    <div class="content" gdColumn.lt-md="1" gdColumn.gt-sm="2" gdRow="1">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

import { Injectable, Optional } from '@angular/core';
import {
  RemoteSettingsService,
  ClientSettings,
} from './remote-settings.service';

@Injectable()
export class EnvironmentService {
  public get environment() {
    const settings = this.tryGetClientSettings();
    return settings;
  }

  constructor(
    private _environment: any,
    @Optional() private remoteSettingsService?: RemoteSettingsService
  ) {}

  private tryGetClientSettings(): ClientSettings | any {
    if (this.remoteSettingsService) {
      return this.remoteSettingsService.clientSettings;
    }

    return this._environment;
  }

  public get appVersion() {
    return this._environment.appVersion;
  }
}

export function EnvironmentServiceFactory(
  environment: any,
  remoteSettingsService?: RemoteSettingsService
) {
  return new EnvironmentService(environment, remoteSettingsService);
}

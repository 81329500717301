<header fxLayoutAlign="space-between" fxLayout="row">
  <div class="header-logo-wrapper" (click)="goTo('HEADER_LOGO_LINK')">
    <img class="header-logo" alt="logo" src="{{ logoSrc }}" />
  </div>
  <span fxFlex="1 1 auto"></span>
  <div class="navigation" fxLayout="row">
    <button fxHide.gt-sm mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" fxHide.gt-sm>
      <button
        mat-menu-item
        *ngFor="let menuItem of menuItems"
        (click)="goTo(menuItem.href)"
      >
        {{ menuItem.text | translate }}
      </button>
    </mat-menu>
    <button
      fxHide.lt-md
      mat-flat-button
      *ngFor="let menuItem of menuItems"
      (click)="goTo(menuItem.href)"
    >
      {{ menuItem.text | translate }}
    </button>
  </div>
</header>

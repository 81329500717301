<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-10">
      <div class="row">
       
        <div class="col-xl-3 mb-3">
          <button type="button" (click)="navigateToTarget('pay')" class="btn btn-block btn-lg btn-success">
            Pay for fine
          </button>
        </div>
     
 
        <div class="col-xl-3  mb-3">
          <button type="button" (click)="navigateToTarget('rep')" class="btn btn-block btn-lg btn-success">
            Rep
          </button>
        </div>
   
      </div>
      <br />
      <br />
      
      <h4>
        Env: <span id="current-environment">{{ this.env() }}</span>
      </h4>
      <h4>
        ClientId: <span id="current-clientid">{{ this.clientId() }}</span>
      </h4>
      <h4>
        Version: <span id="current-version">{{ this.version() }}</span>
      </h4>
      <h4>
        TestId: <span id="current-testid">{{ this.testId() }}</span>
      </h4>
    </div>
  </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { RouterService, LanguagesService } from '../../../services';
import { AuthService } from '../../../services/auth.service';
import { AuthService as SharedAuthService } from '@frontend/shared/services';
import { AppStorageEnum } from '@frontend/shared/models';

@Component({
  selector: 'pp-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  @Output() private close = new EventEmitter<any>();
  @Input() public asDropdown = false;
  public isFormSubmitted = false;
  public isError = false;
  public isLoading = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private routerService: RouterService,
    private sharedAuthService: SharedAuthService,
    private languagesService: LanguagesService
  ) {}

  public ngOnInit() {
    this.auth.logout();
    this.loginForm = this.fb.group({
      plateNumber: ['', [Validators.required, Validators.minLength(4)]], // => password
      refNumber: ['', [Validators.required, Validators.minLength(4)]], // => username
    });
  }

  public submitLogin() {
    this.isFormSubmitted = true;
    // send data to auth service
    if (this.loginForm.valid) {
      this.isError = false;
      this.isLoading = true;
      const data = this.loginForm.getRawValue();
      sessionStorage.setItem('numberPlate', data.plateNumber);
      localStorage.setItem('refNumber', data.refNumber);
   //   const isNewPaymentFlow = data.refNumber.startsWith('2');
      const clientId = environment.clientId; //isNewPaymentFlow
        // ? environment.clientId
        // : environment.clientId2;

   //   if (isNewPaymentFlow) {
        this.newPaymentFlow(data, clientId);
      // } else {
      //   this.oldPaymentFlow(data, clientId);
      // }
    }
  }

  public dismiss() {
    this.close.emit(false);
  }

  public closeLogin() {
    this.router.navigate([this.routerService.getPreviousUrl()]);
  }

  private oldPaymentFlow(data, clientId) {
    this.auth.login(data.refNumber, data.plateNumber, clientId).subscribe(
      () => {
        this.router.navigate(['/account']);

        this.close.emit(true);
      },
      (error) => {
        this.loginForm.setErrors({ incorrect: true });
        this.isError = true;
        this.isLoading = false;
      },
      () => (this.isLoading = false)
    );
  }

  private newPaymentFlow(data, clientId) {
    this.sharedAuthService
      .login(data.refNumber, data.plateNumber, clientId)
      .subscribe(
        () => {
          sessionStorage.setItem(
            AppStorageEnum.temp,
            JSON.stringify(this.loginForm.value)
          );
          this.router.navigate([
            this.languagesService.getCurrentLang().id,
            'pay',
            'step2',
          ]);

          this.close.emit(true);
        },
        () => {
          this.loginForm.setErrors({ incorrect: true });
          this.isError = true;

          this.isLoading = false;
        },
        () => (this.isLoading = false)
      );
  }
}

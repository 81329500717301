import { Multimedia } from '@frontend/shared/models';
import { Action, createReducer, on, UPDATE } from '@ngrx/store';
import * as RepActions from './rep.actions';

export const REP_FEATURE_KEY = 'rep';

export interface PaymentData {
  registrationNumber: string;
  referenceNumber: string;
}

export interface ComplaintData {
  cpr: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  email: string;
  complaint: string;
  name: string;
}

export interface ImagesData {
  images: Multimedia[];
}

export const IMAGES_DATA_STATE_KEY = 'imagesData';

export interface State {
  paymentData: PaymentData;
  complaintData: ComplaintData;

  imagesData: ImagesData;

  completedSteps: number[];
  currentStep: number;
  step1Error: string;
  isLoading: boolean;
}

export interface RepPartialState {
  readonly [REP_FEATURE_KEY]: State;
}

export const initialState: State = {
  complaintData: initialComplaintData(),
  paymentData: initialPaymentData(),

  imagesData: initialImagesData(),
  completedSteps: [],
  currentStep: 1,
  step1Error: null,
  isLoading: false,
};

const repReducer = createReducer(
  initialState,
  on(RepActions.imagesDataChanged, (state, { imagesData }) => ({
    ...state,
    imagesData: { ...state.imagesData, ...imagesData },
  })),
  on(RepActions.paymentDataChanged, (state, { paymentData }) => ({
    ...state,
    paymentData: { ...state.paymentData, ...paymentData },
  })),
  on(RepActions.complaintDataChanged, (state, { complaintData }) => ({
    ...state,
    complaintData: { ...state.complaintData, ...complaintData },
  })),
  on(RepActions.step1Completed, onStepCompleted(1, 2)),
  on(RepActions.step2Completed, onStepCompleted(2, 3)),
  on(RepActions.step3Completed, onStepCompleted(3, 4)),
  on(RepActions.submitRepSuccess, onStepCompleted(3, 4)),
  on(RepActions.repFinish, (state) => ({
    ...initialState,
    currentStep: 1,
  })),
  on(RepActions.setCurrentStep, (state, { currentStep }) => ({
    ...state,
    currentStep,
  })),
  on(RepActions.step1Error, (state, { error }) => ({
    ...state,
    step1Error: error,
    isLoading: false,
  })),
  on(RepActions.step1Success, (state) => ({
    ...state,
    step1Error: null,
    isLoading: false,
  })),
  on(RepActions.step1Login, (state) => ({ ...state, isLoading: true })),
  on(RepActions.submitRep, (state) => ({ ...state, isLoading: true })),
  on(RepActions.addAnotherRep, (state: State) => ({
    ...state,
    paymentData: {
      ...state.paymentData,
      referenceNumber: null,
    },
    complaintData: {
      ...state.complaintData,
      complaint: null,
    },
    completedSteps: [],
  }))
);

function initialPaymentData(): PaymentData {
  return {
    registrationNumber: null,
    referenceNumber: null,
  };
}
function initialComplaintData(): ComplaintData {
  return {
    cpr: null,
    email: null,
    complaint: null,
    address: null,
    postalCode: null,
    city: null,
    country: null,
    name: null,
  };
}

function initialImagesData(): ImagesData {
  return {
    images: [],
  };
}

function onStepCompleted(stepNumber: number, nextStepNumber: number) {
  return (state: { completedSteps: number[] }) => ({
    ...state,
    completedSteps: state.completedSteps.includes(stepNumber)
      ? state.completedSteps
      : [...state.completedSteps, stepNumber],
    currentStep: nextStepNumber,
    isLoading: false,
  });
}

export function reducer(state: State | undefined, action: Action) {
  if (action.type === UPDATE) {
    // when loading the state for the first time always initialize it with initial state, and initialize rest with storage state
    return {
      ...initialState,
      ...state,
    };
  }
  return repReducer(state, action);
}

import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { CommonCardClass } from '../common-card-class/common-card-class';

export interface CreditCardFormModel {
  creditCardNumber: string;
  name: string;
  expdate: string;
  cvc: string;
}

@Component({
  selector: 'frontend-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss'],
})
export class CreditCardFormComponent
  extends CommonCardClass
  implements OnInit, AfterViewInit {
  @Input() initialFormData: CreditCardFormModel;
  @Output() formChanged = new EventEmitter<CreditCardFormModel>();
  @Output() formValidChanged = new EventEmitter<boolean>();

  get cardNumberControl(): AbstractControl {
    return this.cardForm.get('creditCardNumber');
  }

  ngOnInit() {
    this.initForm();
    if (this.initialFormData)
      this.cardForm.patchValue(this.initialFormData, { emitEvent: false });

    this.cardForm.valueChanges.subscribe(() => {
      this.formChanged.emit(this.cardForm.getRawValue());

      if (this.cardForm.valid) this.formValidChanged.emit(true);
      else this.formValidChanged.emit(false);
    });
  }

  ngAfterViewInit() {
    this.createCSEinstance();
  }

  submitPayment(form: FormGroup) {
    // todo below logic should be contained in a service
    const obj = {
      encryptedData: this.encryptData(form, this.cseInstance),
      accountNumber: this.getMaskedCardNumber(),
      accountName: this.getMaskedName(),
      expDate: form.get('expdate').value.replace(/[\s]/g, ''),
      timestamp: Date.now(),
    };
  }
}

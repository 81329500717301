import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from './components/components.module';
import { HttpTranslateFactory } from './helpers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FailoverInterceptor } from './interceptors/failover.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { AuthModule } from './pages/auth/auth.module';
import { HomeModule } from './pages/home/home.module';
import {
  InvoiceService,
  LanguagesService,
  LayoutService,
  LoaderService,
  PaymentService,
  PaymentCoreService,
  RouterService,
} from './services';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import {
  EnvironmentService,
  EnvironmentServiceFactory,
  LanguagesService as LanguagesServiceRoot,
} from '@frontend/shared/services';
import { environment } from '../environments/environment';

import { storageSync } from '@larscom/ngrx-store-storagesync';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { REP_FEATURE_KEY, IMAGES_DATA_STATE_KEY } from '@frontend/shared/rep';

const SERVICES = [
  AuthService,
  InvoiceService,
  LanguagesService,
  LayoutService,
  LoaderService,
  RouterService,
  PaymentService,
  PaymentCoreService,
  ApiService,
];

const COMPONENTS = [];

export function storageSyncReducer(reducer: ActionReducer<any>) {
  const metaReducer = storageSync<any>({
    features: [
      {
        stateKey: REP_FEATURE_KEY,
        storageForFeature: window.sessionStorage,
        excludeKeys: [IMAGES_DATA_STATE_KEY],
      },
    ],
    storage: window.localStorage,
  });

  return metaReducer(reducer);
}

@NgModule({
  declarations: [AppComponent, ...COMPONENTS],
  imports: [
    BrowserModule,

    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    HomeModule,
    ComponentsModule,
    AuthModule,

    StoreModule.forRoot(
      {},
      {
        metaReducers: [storageSyncReducer],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [
    ...SERVICES,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FailoverInterceptor,
      multi: true,
    },
    {
      provide: EnvironmentService,
      useFactory: () => EnvironmentServiceFactory(environment),
    },
    {
      provide: LanguagesServiceRoot,
      useClass: LanguagesService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
